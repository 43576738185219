

























import { Component, Vue } from 'vue-property-decorator'
import { RouteNames } from '@/router'
import { BButton } from 'bootstrap-vue'

@Component({ components: { BButton } })
export default class ErrorNoMoney extends Vue {
  readonly RouteNames = RouteNames
}
